import React from 'react';
import styled from 'styled-components';

import {
  SectionComponent,
  HeadlineComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 2rem;
`;

export const ParagraphComponent = styled.p`
  font-size: ${({ size }) => size || '1rem'};
  font-weight: 400;
  text-align: ${({ align }) => align && align};
  padding-bottom: ${({ pb }) => pb || '1.5rem'};
`;

function PrivacyPolicyPage() {

  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        link="https://teampoker.com/privacy-policy"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Privacy Policy</HeadlineComponent>
        <Container>
        <ParagraphComponent>
        Team Poker LLC (“us”, “we”, or “our”) operates www.teampoker.com (the “Site”). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.
</ParagraphComponent>
<ParagraphComponent>
We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy. Information Collection and Use:
</ParagraphComponent>
<ParagraphComponent>
While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally, identifiable information may include, but is not limited to your name and contact information (“Personal Information”). This Personal Information is for Team Poker LLC use only and will not be intentionally released to any third party without permission.
</ParagraphComponent>
<ParagraphComponent>
Cookies: Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive. Like many sites, we use “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site. 
</ParagraphComponent>
<ParagraphComponent>
Security: The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
        </ParagraphComponent>
<ParagraphComponent>
Changes to This Privacy Policy: Team Poker LLC may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site. You are advised to review this policy and the other Terms and Conditions along with the Site License from time to time. You are advised to review this Privacy Policy periodically for any changes. You are considered informed of any Site Privacy Policies upon the posting of the policy.
        </ParagraphComponent>
<ParagraphComponent>
Contact Us: If you have any questions about the Privacy Policy, please contact us.
        </ParagraphComponent>
        </Container>

      </SectionComponent>
    </Layout>
  );
}

export default PrivacyPolicyPage;